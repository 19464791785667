import { securedAxios } from "@/utils/securedAxios";
import { i18n } from "@/utils/i18n";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    endpoint: "/logistic/shipments/",
    key: "logistic_shipment",
    fetchKey: "shipments", // for getting all records from API. clients : [{...},{...},{...}]
    modulesHasCustomFields: ["shipment", "fulfillment"],
    // permissionKey: "contact.contact_",
    permissionKey: "", // XXXX come back again..
    fieldTypes: [
      "inventory_product_var",
      "accounting_invoice",
      "custom_form",
      "fulfillment",
      "shipment",
      "accounting_purchase_order",
      "accounting_proforma_invoice",
      "accounting_invoice_purchase"
    ],
    willRemoveFields: ["created_at", "created_by_id", "created_by_type", "note", "updated_at", "updated_by_id", "updated_by_type"],
    activeFieldType: {},

    statusList: [
      {
        name: "active",
        id: "active",
        text: "pages.logistics.status_list.active",
        label: "pages.logistics.status_list.active",
        color: "primary"
      },
      {
        name: "pending",
        id: "pending",
        text: "pages.logistics.status_list.pending",
        label: "pages.logistics.status_list.pending",
        color: "danger",
        use_for_invoice: true
      },
      {
        name: "sent",
        id: "sent",
        text: "pages.logistics.status_list.sent",
        label: "pages.logistics.status_list.sent",
        color: "warning"
      },
      {
        name: "received",
        id: "received",
        text: "pages.logistics.status_list.received",
        label: "pages.logistics.status_list.received",
        color: "info"
      },
      {
        name: "ready",
        id: "ready",
        text: "pages.logistics.status_list.ready",
        label: "pages.logistics.status_list.ready",
        color: "success-2"
      },
      {
        name: "completed",
        id: "completed",
        text: "pages.logistics.status_list.completed",
        label: "pages.logistics.status_list.completed",
        color: "success"
      },
      {
        name: "waiting_backorder",
        id: "waiting_backorder",
        text: "pages.logistics.status_list.waiting_backorder",
        label: "pages.logistics.status_list.waiting_backorder",
        color: "secondary"
      },
      {
        name: "processing",
        id: "processing",
        text: "pages.logistics.status_list.processing",
        label: "pages.logistics.status_list.processing",
        color: "primary",
        use_for_invoice: true
      },
      {
        name: "partial",
        id: "partial",
        text: "pages.logistics.status_list.partial",
        label: "pages.logistics.status_list.partial",
        color: "warning",
        use_for_invoice: true
      },
      {
        name: "fully",
        id: "fully",
        text: "pages.logistics.status_list.fully",
        label: "pages.logistics.status_list.fully",
        color: "success",
        use_for_invoice: true
      },
      {
        name: "cancelled",
        id: "cancelled",
        text: "pages.logistics.status_list.cancelled",
        label: "pages.logistics.status_list.cancelled",
        color: "dark"
      },
    ],

    logisticTypeList: [
      {
        name: "fulfillment",
        id: "fulfillment",
        text: i18n.t("pages.logistics.logisticTypeList.fulfillment"),
        label: i18n.t("pages.logistics.logisticTypeList.fulfillment")
      },
      {
        name: "shipment",
        id: "shipment",
        text: i18n.t("pages.logistics.logisticTypeList.shipment"),
        label: i18n.t("pages.logistics.logisticTypeList.shipment")
      },
      {
        name: "transfer",
        id: "transfer",
        text: i18n.t("pages.logistics.logisticTypeList.transfer"),
        label: i18n.t("pages.logistics.logisticTypeList.transfer")
      }
    ],
    shippedObject: [
      {
        name: "inventory_product",
        value: "inventory_product",
        text: i18n.t("pages.logistics.shippedObject.inventory_product")
      },
      {
        name: "custom",
        value: "custom",
        text: i18n.t("pages.logistics.shippedObject.custom")
      }
    ],
    shippedStockType: [
      {
        name: "unit",
        text: i18n.t("pages.logistics.shippedStockType.unit")
      },
      {
        name: "metage",
        text: i18n.t("pages.logistics.shippedStockType.metage")
      }
    ],
    shippedMetageUnit: ["in", "kg", "cm"],
    packageTypeList: [
      {
        name: "box",
        id: "box",
        text: i18n.t("pages.logistics.packageTypeList.box"),
        label: i18n.t("pages.logistics.packageTypeList.box")
      },
      {
        name: "envelope",
        id: "envelope",
        text: i18n.t("pages.logistics.packageTypeList.envelope"),
        label: i18n.t("pages.logistics.packageTypeList.envelope")
      },
      {
        name: "tube",
        id: "tube",
        text: i18n.t("pages.logistics.packageTypeList.tube"),
        label: i18n.t("pages.logistics.packageTypeList.tube")
      }
    ],
    locations: {},
    shipmentMethodList: [
      {
        id: "package",
        label: i18n.t("pages.logistics.shipmentMethodList.package")
      },
      {
        id: "pickup",
        label: i18n.t("pages.logistics.shipmentMethodList.pickup")
      }
    ]
  },
  mutations: {
    setActiveFieldType(state, activeFieldType) {
      let index = state.fieldTypes.indexOf(activeFieldType);
      if (index > -1) {
        state.activeFieldType = state.fieldTypes[index];
      }
    },
    setLocations(state, locations) {
      state.locations = locations;
    }
  },
  actions: {
    insert({ state }, updateData) {
      // Will be removed in the future because of we will put a select box in form
      updateData.general_information.group_ids = [];

      // Remove Temproary Contact People ID
      for (let key in updateData.contact_people_attributes) {
        if (updateData.contact_people_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.contact_people_attributes[key].id;
          delete updateData.contact_people_attributes[key].tempID;
        }
      }
      // Remove Temproary Phone ID
      for (let key in updateData.phones_attributes) {
        if (updateData.phones_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.phones_attributes[key].id;
          delete updateData.phones_attributes[key].tempID;
        }
      }
      // Remove Temproary Address ID
      for (let key in updateData.addresses_attributes) {
        if (updateData.addresses_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.addresses_attributes[key].id;
          delete updateData.addresses_attributes[key].tempID;
        }
      }

      let willSaveData = {
        type: updateData.type,
        ...updateData.general_information,
        ...updateData.customFieldModels,
        contact_people_attributes: { ...updateData.contact_people_attributes },
        phones_attributes: { ...updateData.phones_attributes },
        addresses_attributes: { ...updateData.addresses_attributes }
      };

      return securedAxios
        .post(state.endpoint, { contact: willSaveData })
        .then(response => {
          return response;
        })
        .catch(e => {
          return e;
        });
    },
    update({ state }, { id, updateData }) {
      // Remove Temproary Contact People ID
      for (let key in updateData.contact_people_attributes) {
        if (updateData.contact_people_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.contact_people_attributes[key].id;
          delete updateData.contact_people_attributes[key].tempID;
        }
      }
      // Remove Temproary Phone ID
      for (let key in updateData.phones_attributes) {
        if (updateData.phones_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.phones_attributes[key].id;
          delete updateData.phones_attributes[key].tempID;
        }
      }
      // Remove Temproary Address ID
      for (let key in updateData.addresses_attributes) {
        if (updateData.addresses_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.addresses_attributes[key].id;
          delete updateData.addresses_attributes[key].tempID;
        }
      }

      let willSaveData = {
        ...updateData.general_information,
        ...updateData.customFieldModels,
        contact_people_attributes: { ...updateData.contact_people_attributes },
        phones_attributes: { ...updateData.phones_attributes },
        addresses_attributes: { ...updateData.addresses_attributes }
      };

      return securedAxios
        .patch(state.endpoint + id, JSON.stringify({ contact: { ...willSaveData } }))
        .then(response => {
          return response;
        })
        .catch(e => {
          return e;
        });
    },
    fetchLocations({ state, commit, dispatch }) {
      if (Object.keys(state.locations).length == 0) {
        securedAxios.get("/locations/").then(response => {
          // Mapping Locations from Whole Location Object
          let locations = response.data.locations.map(location => {
            return {
              id: location.id,
              location_name: location.name
            };
          });
          // Convert to Object from Array for Mapping id : location_name
          locations = Object.assign({}, locations);
          let location_objects = {};

          // Creating New Object Mapping
          for (let key in locations) {
            location_objects[locations[key].id] = locations[key].location_name;
          }
          commit("setLocations", location_objects);
        });
      }
    }
  },
  getters: {
    getFieldTypes(state) {
      return state.fieldTypes;
    },
    modulesHasCustomFields: state => state.modulesHasCustomFields,
    statusList: (state) => state.statusList?.map(status => {
      return {
        ...status,
        text: i18n.t(`pages.logistics.status_list.${status.name}`),
        label: i18n.t(`pages.logistics.status_list.${status.name}`)
      };
    }),
    shippedObject: state => state.shippedObject,
    shippedStockType: state => state.shippedStockType,
    shippedMetageUnit: state => state.shippedMetageUnit,
    packageTypeList: state => state.packageTypeList,
    logisticStatusListForAccounting: (_, getters) => {
      return getters.statusList?.filter(s => s.use_for_invoice);
    },
    logisticStatusListForWidget: (_, getters) =>
      getters.statusList?.filter(s => ["ready", "pending", "processing", "active", "completed", "waiting_backorder"].includes(s.id)),
    logisticTypeList: state => state.logisticTypeList || [],
    shipmentMethodList: state => state.shipmentMethodList || []
  }
};
