import { securedAxios } from "@/utils/securedAxios";
import { i18n } from "@/utils/i18n";

export default {
  namespaced: true,
  state: {
    endpoint: "/contacts/",
    key: "contact",
    fetchKey: "contacts", // for getting all records from API. clients : [{...},{...},{...}]
    modulesHasCustomFields: ["customer", "supplier"],
    permissionKey: "contact.contact_",
    fieldTypes: [
      "inventory_product_var",
      "accounting_invoice",
      "custom_form",
      "inventory_fulfilment",
      "inventory_shipment",
      "accounting_purchase_order",
      "accounting_proforma_invoice",
      "accounting_invoice_purchase",
    ],
    favorites_inventory_products: {
      typeList: [
        {
          name: "profit_addition", //! percentage
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.typeList.profit_addition"),
        },
        {
          name: "profit_discount", //! percentage
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.typeList.profit_discount"),
        },
        {
          name: "margin", //! Calculation.
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.typeList.margin"),
        },
        {
          name: "fixed_price", //! money
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.typeList.fixed_price"),
        },
      ],
      amountTypeList: [
        {
          name: "profit_addition", //! percentage
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.amountTypeList.profit_addition"),
        },
        {
          name: "profit_discount", //! percentage
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.amountTypeList.profit_discount"),
        },
        {
          name: "margin", //! Calculation.
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.amountTypeList.margin"),
        },
        {
          name: "fixed_price", //! money
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.amountTypeList.fixed_price"),
        },
      ],
      applyToList: [
        {
          value: "product",
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToList.product"),
        },
        {
          value: "product_type",
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToList.product_type"),
        },
        // {
        //   value: "product_groups",
        //   text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToList.product_groups"),
        // },
        {
          value: "inventory_product_brand",
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToList.inventory_product_brand"),
        },
        // {
        //   value: "contact_supplier",
        //   text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToList.contact_supplier"),
        // },
        {
          value: "all_inventory",
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToList.all_inventory"),
        },
      ],
      applyToStockTypeList: [
        {
          name: "unit_quantity",
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToStockTypeList.unit_quantity"),
        },
        {
          name: "bundle_unit",
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToStockTypeList.bundle_unit"),
        },
        {
          name: "metage_quantity",
          text: i18n.t("pages.contacts.specific_inventory_prices_modal.enums.applyToStockTypeList.metage_quantity"),
        },
      ],
    },
    accounting_invoice_rules: {
      reward_type: [
        {
          text: i18n.t("pages.contacts.accounting_invoice_rules_modal.enums.reward_type.free_shipping"),
          name: "free_shipping",
        },
      ],
      trigger: [
        {
          text: i18n.t("pages.contacts.accounting_invoice_rules_modal.enums.trigger.invoice_total"),
          name: "invoice_total",
        },
      ],
    },
    willRemoveFields: ["created_at", "created_by_id", "created_by_type", "note", "updated_at", "updated_by_id", "updated_by_type"],
    activeFieldType: {},
    contactTypeList: [
      {
        name: "customer",
        text: i18n.t("pages.contacts.contactTypeList.customer"),
      },
      {
        name: "supplier",
        text: i18n.t("pages.contacts.contactTypeList.supplier"),
      },
    ],
    balanceSettings: {
      coverList: [
        {
          id: "all",
          name: "all",
          text: i18n.t("pages.contacts.coverList.all"),
          label: i18n.t("pages.contacts.coverList.all"),
        },
        {
          id: "unpaid_invoices",
          name: "unpaid_invoices",
          text: i18n.t("pages.contacts.coverList.unpaid_invoices"),
          label: i18n.t("pages.contacts.coverList.unpaid_invoices"),
        },
        {
          id: "active_orders",
          name: "active_orders",
          text: i18n.t("pages.contacts.coverList.active_orders"),
          label: i18n.t("pages.contacts.coverList.active_orders"),
        },
      ],
    },
    prepressSettings: {
      storeTimeList: [
        {
          id: "one_month",
          label: i18n.t("pages.contacts.storeTimeList.one_month"),
        },
        {
          id: "six_months",
          label: i18n.t("pages.contacts.storeTimeList.six_months"),
        },
        {
          id: "four_years",
          label: i18n.t("pages.contacts.storeTimeList.four_years"),
        },
      ],
    }
  },
  mutations: {
    setActiveFieldType(state, activeFieldType) {
      let index = state.fieldTypes.indexOf(activeFieldType);
      if (index > -1) {
        state.activeFieldType = state.fieldTypes[index];
      }
    },
  },
  actions: {
    insert({ state }, updateData) {
      // Remove Temproary Contact People ID
      for (let key in updateData.contact_people_attributes) {
        if (updateData.contact_people_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.contact_people_attributes[key].id;
          delete updateData.contact_people_attributes[key].tempID;
        }

        updateData.contact_people_attributes[key].type = updateData.contact_people_attributes[key]?.type?.id || null;
      }
      // Remove Temproary Phone ID
      for (let key in updateData.phones_attributes) {
        if (updateData.phones_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.phones_attributes[key].id;
          delete updateData.phones_attributes[key].tempID;
        }
      }
      // Remove Temproary Address ID
      for (let key in updateData.addresses_attributes) {
        if (updateData.addresses_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.addresses_attributes[key].id;
          delete updateData.addresses_attributes[key].tempID;
        }
      }

      let willSaveData = {
        type: updateData.type,
        note: updateData.note,
        company_name: updateData.company_name,
        // company_code: updateData.company_code,
        group_ids: updateData.group_ids,
        custom_field_group: updateData.custom_field_group,
        accounting_currency: updateData.accounting_currency,
        accounting_payment_term: updateData.accounting_payment_term,
        accounting_payment_method: updateData.accounting_payment_method,
        inventory_price_type: updateData.inventory_price_type,
        accounting_tax: updateData.accounting_tax,
        ...updateData.customFieldModels,
        contact_people_attributes: { ...updateData.contact_people_attributes },
        phones_attributes: { ...updateData.phones_attributes },
        addresses_attributes: { ...updateData.addresses_attributes },
        user_representatives_attributes: updateData.user_representatives_attributes,
        hive_project_customer_settings: updateData.hive_project_customer_settings,
        allow_commission: updateData.allow_commission || false,
        accounting_invoicing_rule_note: updateData.accounting_invoicing_rule_note,
        preferred_language: updateData.preferred_language,
      };
      if (updateData?.rating) willSaveData.rating = updateData.rating;

      return securedAxios
        .post(state.endpoint, { contact: willSaveData })
        .then((response) => {
          return response;
        })
        .catch((e) => {
          return e;
        });
    },
    update({ state }, { id, updateData }) {
      // Remove Temproary Contact People ID
      for (let key in updateData.contact_people_attributes) {
        if (updateData.contact_people_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.contact_people_attributes[key].id;
          delete updateData.contact_people_attributes[key].tempID;
        }
        updateData.contact_people_attributes[key].type = updateData.contact_people_attributes[key]?.type?.id || null;
      }
      // Remove Temproary Phone ID
      for (let key in updateData.phones_attributes) {
        if (updateData.phones_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.phones_attributes[key].id;
          delete updateData.phones_attributes[key].tempID;
        }
      }
      // Remove Temproary Address ID
      for (let key in updateData.addresses_attributes) {
        if (updateData.addresses_attributes[key].hasOwnProperty("tempID")) {
          delete updateData.addresses_attributes[key].id;
          delete updateData.addresses_attributes[key].tempID;
        }
      }

      let willSaveData = {
        company_name: updateData.company_name,
        // company_code: updateData.company_code,
        group_ids: updateData.group_ids,
        custom_field_group: updateData.custom_field_group,
        note: updateData.note,
        accounting_currency: updateData.accounting_currency,
        accounting_payment_term: updateData.accounting_payment_term,
        accounting_payment_method: updateData.accounting_payment_method,
        inventory_price_type: updateData.inventory_price_type,
        accounting_tax: updateData.accounting_tax,
        ...updateData.customFieldModels,
        contact_people_attributes: { ...updateData.contact_people_attributes },
        phones_attributes: { ...updateData.phones_attributes },
        addresses_attributes: { ...updateData.addresses_attributes },
        user_representatives_attributes: updateData.user_representatives_attributes,
        hive_project_customer_settings: updateData.hive_project_customer_settings,
        allow_commission: updateData.allow_commission || false,
        suspended: updateData.suspended || false,
        accounting_invoicing_rule_note: updateData.accounting_invoicing_rule_note,
        preferred_language: updateData.preferred_language,
        balance_settings: updateData?.balance_settings,
        prepress_work_order_file_store_time: updateData?.prepress_work_order_file_store_time,
      };

      if (updateData?.rating) willSaveData.rating = updateData.rating;

      return securedAxios
        .patch(state.endpoint + id, JSON.stringify({ contact: { ...willSaveData } }))
        .then((response) => {
          return response;
        })
        .catch((e) => {
          return e;
        });
    },
  },
  getters: {
    getFieldTypes: (state) => state.fieldTypes,
    amountTypeList: (state) => state.favorites_inventory_products.amountTypeList,
    typeList: (state) => state.favorites_inventory_products.typeList,
    applyToList: (state) => state.favorites_inventory_products.applyToList,
    applyToStockTypeList: (state) => state.favorites_inventory_products.applyToStockTypeList,
    contactTypeList: (state) => state.contactTypeList,
    rewardTypeList: (state) => state.accounting_invoice_rules.reward_type,
    triggerList: (state) => state.accounting_invoice_rules.trigger,
    balanceSettingsCoverList: (state) => state.balanceSettings.coverList,
    storeTimeList: (state) => state.prepressSettings.storeTimeList,
  },
};
